import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./sass/index.scss";
import App from "./components/App";
import ContentList from "./components/ContentList";
import ContactUs from "./components/ContactUs";
import Home from "./components/Home";
import AdminLogin from "./components/AdminLogin";
import AdminImportContent from "./components/AdminImportContent";
import { AdminProvider } from "./components/AdminContext";
import AdminTrailerUpload from "./components/AdminTrailerUpload";
import AdminPosterUpload from "./components/AdminPosterUpload";
import AdminUpdateContent from "./components/AdminUpdateContent";
import SearchContent from "./components/SeachContent";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/content/genre/:genreName",
    element: <ContentList />,
  },
  {
    path: "/content/:packId",
    element: <App />,
  },
  {
    path: "/content/search",
    element: <SearchContent />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/admin/login",
    element: <AdminLogin />,
  },
  {
    path: "/admin/import-content",
    element: <AdminImportContent />,
  },
  {
    path: "/admin/trailer-upload/:id",
    element: <AdminTrailerUpload />,
  },
  // { // Not fully ready
  //   path: "/admin/poster-upload/:id",
  //   element: <AdminPosterUpload />,
  // },
  {
    path: "/admin/update-content/:id",
    element: <AdminUpdateContent />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AdminProvider>
      <RouterProvider router={router} />
    </AdminProvider>
  </React.StrictMode>
);
