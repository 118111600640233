import { useEffect, useState } from "react";

import Trailer from "./Trailer";
import Loader from "./Loader";
import Menu from "./Menu";
import Footer from "./Footer";
import RequestModel from "./RequestModel";
import { useAdmin } from "./AdminContext";
import { Link } from "react-router-dom";

function ContentDetail({ packId }) {
  const [contentDetail, setContentDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isShow, setIsShow] = useState(false);

  const { isAdmin } = useAdmin();

  // function sendEmail(title) {
  //   const subject = `Requesting Screener For ${title}`;
  //   const message = `Hi, I would lie to request for screener for $${title}`;

  //   window.location.href = `mailto:content_sales@astro.com.my?subject=${encodeURIComponent(
  //     subject
  //   )}&body=${encodeURIComponent(message)}`;
  // }

  useEffect(
    function () {
      async function fetchContentDetails() {
        setIsLoading(true);
        try {
          const rawResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_API}/content/${packId}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          const content = await rawResponse.json();
          if (content) {
            console.log("🚀 ~ fetchContentDetails ~ content.data[0]:", content);
            setContentDetail(content);
          }
        } catch (error) {
          console.log(error);
          throw new Error();
        } finally {
          setIsLoading(false);
        }
      }
      fetchContentDetails();
    },
    [packId]
  );

  return (
    <>
      <Menu />
      {isLoading && <Loader />}
      {!isLoading && (
        <div
          className="content"
          style={{
            backgroundImage: `linear-gradient(45deg,
          rgba(0, 0, 0, 0.6),
          rgba(0, 0, 0, 0.6)),url(
          "${contentDetail.posters.detail.fileUrl}"
        )`,
          }}
        >
          <div className="content__header">
            <div className="content__metadata">
              <h1 className="content__metadata-title">
                {contentDetail?.title}
              </h1>
              <div className="content__metadata-genre">
                {contentDetail?.genre} | {contentDetail?.subGenre.join(", ")}
              </div>
              <div className="content__metadata-genre">
                {contentDetail?.episodeNumber > 0
                  ? `${contentDetail?.episodeNumber} Episodes | ${contentDetail?.duration} Minutes`
                  : `${contentDetail?.duration} Minutes`}
              </div>

              <div className="content__metadata-synopsis">
                {contentDetail?.longSynopsis}
              </div>
              {/* <div className="language">
                <Language
                  icon="pacman"
                  title="Audio"
                  text={contentDetail?.audioLanguageLabel}
                />
                <Language
                  icon="typing"
                  title="Subtitle"
                  text={contentDetail?.subTitle.join(", ")}
                />
              </div> */}

              <div className="rating">
                <RatingBox
                  ratingName="Language"
                  ratingScore={contentDetail?.audioLanguageLabel}
                />
                <RatingBox
                  ratingName="Classification"
                  ratingScore={contentDetail?.certification}
                />
                <RatingBox
                  ratingName="Release"
                  ratingScore={contentDetail?.yearOfRealease}
                />
                {contentDetail?.subTitle.length > 0 && (
                  <RatingBox
                    ratingName="Subtitles"
                    ratingScore={contentDetail?.subTitle.join(", ")}
                  />
                )}
              </div>

              {/* <div className="director">
                <img
                  className="director__icon"
                  src="/dir.svg"
                  alt="director chair"
                />
                <span className="director__name">
                  {[contentDetail?.directors]}
                </span>
              </div>
              <div className="cast">
                <h2 className="cast__heading">Casting</h2>
                <div className="cast__group">
                  {contentDetail?.actors.map((actor) => (
                    <Cast name={actor} key={actor} />
                  ))}
                </div>
              </div> */}
              <div className="content-action">
                <button
                  type="button"
                  className="content-action__btn"
                  // onClick={() => sendEmail(contentDetail?.title)}
                  onClick={() => setIsShow(true)}
                >
                  Request Screener
                </button>

                {isAdmin && (
                  <button className="update-content">
                    <Link to={`/admin/update-content/${packId}`}>Update</Link>
                  </button>
                )}

                {isAdmin && !contentDetail.trailerUrl && (
                  <button className="update-content">
                    <Link to={`/admin/trailer-upload/${packId}`}>
                      Upload trailer
                    </Link>
                  </button>
                )}
              </div>
            </div>
            <div className="content__trailer">
              {contentDetail.trailerUrl && (
                <Trailer trailer={contentDetail?.trailerUrl} />
              )}
              {!contentDetail.trailerUrl && (
                <img
                  className="content__trailer-player"
                  alt={`${contentDetail.title} poster`}
                  src={contentDetail.posters.detail.fileUrl}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <RequestModel
        isShow={isShow}
        setIsShow={setIsShow}
        title={contentDetail?.title}
      />
      <Footer />
    </>
  );
}

// function Language({ icon, title, text }) {
//   return (
//     <div className="language__box">
//       <span className="language__box-header">
//         <svg className="language__box-icon">
//           <use xlinkHref={`/sprite.svg#icon-${icon}`}></use>
//         </svg>
//       </span>
//       <div className="language__box-detail">
//         <h4 className="language__box-detail-title">{title}</h4>
//         <span className="language__box-detail-title">{text}</span>
//       </div>
//     </div>
//   );
// }

function RatingBox({ ratingName, ratingScore }) {
  return (
    <div className="rating__box">
      <span className="rating__box-name">{ratingName}</span>
      <span
        className="rating__box-score"
        style={{ fontSize: ratingScore.length > 15 ? "1.5rem" : "2rem" }}
      >
        {ratingScore}
      </span>
    </div>
  );
}

// function Cast({ name }) {
//   return (
//     <div className="cast__box">
//       {/* <img
//         src="/actor.jpg"
//         alt="cast placeholder"
//         className="cast__box-image"
//       /> */}
//       <span className="cast__box-name">{name}</span>
//     </div>
//   );
// }

export default ContentDetail;
